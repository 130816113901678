@import "./src/style/variables.less";
.products_highlights_box {
	cursor: pointer;
	padding: 20px 32px 30px 32px;
	background: #fff;
	margin-bottom: 20px;

	.products_highlights_title {
		font-size: 16px;
		line-height: 30px;
		font-weight: 600;
		color: #19191A;
		padding-bottom: 20px;
		margin-bottom: 30px;
		border-bottom: 1px solid #E5E5E5;
		display: flex;
		justify-content: space-between;
	}

	.products_highlights_ctn {
		width: 100%;

		.highlights_component_box {
			position: relative;
			margin: 20px 0;

			>div {
				&:first-child {
					padding: 30px 0;
					border-bottom: 1px solid #e5e5e5;
				}
			}

			&:hover {
				.highlights_component_mask {
					display: flex;
				}

				.iconfont_delete {
					display: inline-block;
				}
			}

			.highlights_component_mask {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.3);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				color: #19191a;
				cursor: pointer;
				display: none;
			}

			.iconfont_delete {
				display: none;
			}
		}
	}

	.iconfont_delete {
		position: absolute;
		right: 24px;
		bottom: 20px;
		cursor: pointer;
		width: 20px;
		height: 20px;
		color: #fff;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		background: rgba(37, 39, 51, 0.5);
		border-radius: 20px;
	}

	.products_highlights_empty {
		display: flex;
		width: 100%;
		height: 608px;
		align-items: center;
		justify-content: center;
		background: #F7F7F7;
		color: #252733;
		font-size: 16px;
		line-height: 24px;
	}

	.highlights_component_empty_box {
		padding: 20px 0;
		border-bottom: 1px solid #E5E5E5;

		&:last-of-type {
			border-bottom: none;
		}
	}

	.highlights_component_empty {
		display: flex;
		width: 100%;
		height: 608px;
		align-items: center;
		justify-content: center;
		background: #F7F7F7;
		color: #252733;
		font-size: 16px;
		line-height: 24px;
		position: relative;
		margin-bottom: 20px;
		transition: all .3s;

		&:hover {
			background: rgba(0, 0, 0, 0.28);
		}
	}
}

.delete_modal_box {
	:global {
		.arco-modal-header {
			.arco-modal-title {
				text-align: left;
			}
		}
	}
}


.import_modal_box {

	:global {
		.arco-modal-header {
			.arco-modal-title {
				text-align: left;
			}
		}
	}

	.block_list_box {
		margin-top: 20px;

		.block_list_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			color: #252733;
			line-height: 22px;
			padding: 10px 16px;
			text-align: left;
			background: rgba(242, 243, 245, 0.8);
			border-radius: 8px 8px 0px 0px;

		}

		.block_list_body {
			height: 58.4vh;
			position: relative;
			overflow: auto;

			.block_list_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				min-height: 190px;
				padding: 10px 16px;

				&.block_list_item_active {
					background: rgba(22, 93, 255, 0.03);
					border-top: 1px solid #EEF1F4;
					border-bottom: 1px solid #EEF1F4;
				}
			}

			.noblock_box {
				display: block;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);

				.noblock_img {
					display: block;
					margin: 0 auto;
				}

				.noblock_info {
					text-align: center;
					margin-top: 10px;
					color: #4E5969;
					font-size: 16px;
					line-height: 24px;
				}

			}
		}

		.block_list_radio {
			width: 56px;

			.block_radio {
				appearance: none;
				-webkit-appearance: none;
				display: inline-block;
				width: 16px;
				height: 16px;
				border: none;
				border-radius: 0;
				padding: 0;
				line-height: 1;
				background: none;
				background-color: transparent;
				outline: none;
				position: relative;
				font-size: 16px;
				cursor: pointer;

				&:before {
					content: "\f051";
					color: #165DFF;
					font-family: "iconfont" !important;
					-webkit-font-smoothing: antialiased;
					cursor: pointer;
				}


				&:checked {
					&:before {
						color: #165DFF;
						font-family: "iconfont" !important;
						-webkit-font-smoothing: antialiased;
						content: "\f050";
					}
				}
			}
		}

		.block_list_image {
			width: 320px;
			padding-right: 40px;

			img {
				display: block;
				max-width: 100%;
			}
		}

		.block_list_desc {
			width: 302px;
			padding-right: 40px;

			img {
				display: block;
				max-width: 100%;
			}
		}
	}
}