@import "./src/style/variables.less";
.manager_detail {
  height: 100vh;
  .edit_content {
    display: flex;
    height: calc(100% - 77px);
    overflow-y: auto;
    .edit_content_left {
      flex: 1;
      border-right: 1px solid #e5e6eb;
      display: flex;
      .line {
        width: 5px;
        height: 100%;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        &:hover span {
          display: flex;
        }
        .fold {
          right: 0 !important;
          display: flex;
          svg {
            transform: rotate(180deg);
          }
        }
        span {
          display: none;
          border: 1px solid #e5e6eb;
          width: 14px;
          height: 44px;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          border-radius: 228px;
          position: absolute;
          top: calc(50% - 22px);
          right: -8px;
          background-color: #fff;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #f7f8fa;
          }
          svg {
            fill: unset !important;
            font-size: 10px;
          }
        }
      }
    }
    .edit_content_right {
      transition: all 0.3s ease-in-out;
      overflow-y: auto;
    }
  }
}
