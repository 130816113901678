@import "./src/style/variables.less";
.solution_modal {
  width: 340px;
  .arco-modal-title {
    text-align: left;
  }
  .content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
