@import "./src/style/variables.less";
.image-view {
  height: 140px;
  box-sizing: border-box;

  .image-label {
    color: #252733;
    font-size: 14px;

    &::before {
      content: '*';
      color: #ff4d4f;
      margin-right: 8px;
    }
  }

  &-content {
    height: 140px;
    box-sizing: border-box;
    margin-top: 12px;
    cursor: pointer;
    width: 100px;

    &-add {
      width: 100%;
      height: 100%;
      background: #f7f8fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #4e5969;
      border-radius: 8px;
      box-sizing: border-box;

      span {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }
    }

    &-preview {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 140px;
      overflow: hidden;

      img {
        width: 100%;
        height: 140px;
        border-radius: 8px;
      }

      &-mask {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: -30px;
        left: 0;
        background: rgba(0, 0, 0, 40%);
        font-size: 14px;
        color: #fff;
        border-radius: 0 0 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
      }

      &:hover {
        .image-view-content-preview-mask {
          bottom: 0;
        }
      }
    }
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
