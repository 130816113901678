@import "./src/style/variables.less";
.custom-view {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &-mask {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -100px;
    left: 0;
    background: rgba(0, 0, 0, 40%);
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }

  &:hover {
    .custom-view-mask {
      bottom: 0;
    }
  }
}
