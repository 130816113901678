@import "./src/style/variables.less";
.global_link_detail {
    .global_link_detail_nav {
        padding: 20px 0;
    }
    .global_link_detail_data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 32px;
        border-radius: 8px;
        border: 1px solid #E5E6EB;
        color: #252733;
        .global_link_detail_data_l {
            display: flex;
            > div {
                margin-right: 40px;
            }
        }
        .global_link_detail_data_r {

        }
      
    }
    .global_link_detail_content {
        .global_link_detail_product {
            min-height: calc(100vh - 32px);
           margin-top: 20px;
           border-radius: 8px;
           background-color: #F7F8FA;
           padding: 32px;
        }
    }
    padding: 0 20px;
}