@import "./src/style/variables.less";
.products_description_box {
	cursor: pointer;
	margin-bottom: 20px;
}
.products_description_box {
	.description_title {
		font-size: 16px;
		line-height: 24px;
		color: #19191a;
		font-weight: 600;
		padding: 25px 0 13px;
	}
	.description_item {
		color: #646466;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 20px;
	}
}
.products_description_empty {
	display: flex;
	width: 100%;
	height: 186px;
	align-items: center;
	justify-content: center;
	background: #F7F7F7;
	color: #252733;
	font-size: 16px;
	line-height: 24px;
}