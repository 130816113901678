@import "./src/style/variables.less";
.quality_testing_program_wrap {
	cursor: pointer;
	padding-bottom: 32px;
	border-bottom: 1px solid #E5E5E5;
	margin-bottom: 32px;

	.quality_testing_program_title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: #19191A;
		margin-bottom: 12px;
	}

	.quality_testing_program_content {
		position: relative;

		.testing_program_image {
			display: block;
			max-width: 100%;
		}

		.testing_program_ctn {
			width: 100%;
			display: inline-block;
			position: absolute;
			top: 50%;
			padding-left: 30%;
			padding-right: 10%;
			width: 100%;
			-webkit-transform: translate3d(0, -50%, 0);
			transform: translate3d(0, -50%, 0);
		}

		.testing_program_title {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: #19191a;
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			margin-bottom: 6px;
			>a {
				color: #19191a;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			>span {
				font-size: 12px;
				line-height: 18px;
				font-weight: 400;
				margin-left: 4px;
			}

		}

		.testing_program_text {
			font-size: 14px;
			line-height: 22px;
			color: #646466;
			width: 100%;
			word-break: break-all;
		}

	}

	.quality_testing_program_empty {
		display: flex;
		width: 100%;
		height: 100px;
		align-items: center;
		justify-content: center;
		background: #F7F7F7;
		color: #252733;
		font-size: 16px;
		line-height: 24px;
	}
}