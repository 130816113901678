@import "./src/style/variables.less";
.edit_content {
  padding: 20px;
  .form_item {
    :global {
      .arco-form-label-item {
        flex: 1;
      }
      .arco-form-item-wrapper {
        width: auto;
        flex: 0;
      }
    }
  }
  .buttons {
    display: flex;
    button {
      flex: 1;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
  .upload_img {
    margin-top: 12px;
    border: 1px solid #165dff;
    padding: 9px 24px;
    border-radius: 8px;
    color: #165dff;
    svg {
      color: #165dff;
      margin-right: 5px;
    }
  }
  .upload_list {
    margin-top: 10px;
  }
}
