@import "./src/style/variables.less";
.fs_switch_table_wrap {
	max-width: 1120px;
	overflow-x: auto;
	margin: 50px auto 30px auto;
	position: relative;
	padding-bottom: 16px;


	.table {
		width: 100%;
		min-width: 900px;
		position: relative;
		table-layout: fixed;

		thead {
			tr {
				vertical-align: top;

				th {
					border-bottom: 1px solid #e5e5e5;
					text-align: center;

					.products_box {
						display: block;
						padding: 50px 0 30px 0;
						color: #19191a;

						.products_img_box {
							display: block;
							max-width: 100%;
							margin: 0 auto 10px auto;
							padding: 0 10px;
						}

						.products_img {
							display: block;
							max-width: 100%;
							margin: 0 auto;
						}

						.products_text {
							color: #19191a;
							font-weight: 400;
							padding: 0 10px;
							text-align: center;
							font-size: 12px;
							line-height: 18px;
							max-height: 36px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;

							>a {
								display: block;
								color: #19191a;
								text-decoration: none;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}

					&:first-child {
						font-size: 12px;
						line-height: 18px;
						color: #19191a;
						padding-left: 16px;
						text-align: left;
						z-index: 10;
						background: #fff;
						width: 184px;

						@media (max-width: 960px) {
							width: 156px;
						}

					}

					&.th_hightlight {
						// background: rgba(143, 143, 143, 0.05);
						z-index: 10;
						position: relative;

						// &::after{
						// 	display: block;
						// 	position: absolute;
						// 	content: ' ';
						// 	width: 40px;
						// 	height: 3px;
						// 	background-color: #c00000;
						// 	left: 50%;
						// 	transform: translateX(-50%);
						// 	bottom: -1px;
						// }
						@media (max-width: 960px) {
							position: relative;
							left: auto;
						}
						.products_box {
							position: relative;
							// &::after{
							// 	display: block;
							// 	position: absolute;
							// 	content: ' ';
							// 	width: 40px;
							// 	height: 3px;
							// 	background-color: #c00000;
							// 	left: 50%;
							// 	transform: translateX(-50%);
							// 	bottom: -1px;
							// }
						}
						.products_text {
							color: #19191a;
						}
					}
				}
			}
		}

		tbody {
			tr {
				td {
					background: #fff;
					border-bottom: 1px solid #e5e5e5;
					padding: 9px 10px 9px 10px;
					text-align: center;
					font-size: 14px;
					line-height: 22px;
					color: #646466;
					.iconfont_select {
						font-size: 20px;
						color: #10a300;
					}
					&:first-child {
						font-size: 12px;
						line-height: 18px;
						color: #19191a;
						padding-left: 16px;
						text-align: left;
						z-index: 10;

						>div {
							// width: 168px;
							// @media (max-width: 960px) {
							// 	width: 140px;
							// }
						}

						@media (max-width: 960px) {
							position: relative;
							left: auto;
						}
					}

					&.td_hightlight {
						background-color: rgba(143, 143, 143, 0.05);
						z-index: 10;

						@media (max-width: 960px) {
							position: relative;
							left: auto;
						}
					}
				}
			}
		}
	}
}

.table_empty {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}