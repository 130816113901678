@import "./src/style/variables.less";
.network_connectivity_box {
	cursor: pointer;
	padding: 20px 32px 30px 32px;
	background: #fff;
	margin-bottom: 20px;

	.network_connectivity_title {
		font-size: 16px;
		line-height: 30px;
		font-weight: 600;
		color: #19191A;
		padding-bottom: 20px;
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 20px;
	}

	.network_connectivity_empty {
		display: flex;
		width: 100%;
		height: 608px;
		align-items: center;
		justify-content: center;
		background: #F7F7F7;
		color: #252733;
		font-size: 16px;
		line-height: 24px;
	}

	.network_connectivity_ctn {
		position: relative;

		.connectivity_title {
			font-size: 20px;
			line-height: 30px;
			color: #19191a;
			text-align: center;
			padding-top: 30px;
			font-weight: 600;
			padding-bottom: 30px;
		}

		.connectivity_tag_wrap {
			.connectivity_tag {
				padding-bottom: 50px;
				padding-top: 30px;
				border-bottom: 1px solid #e5e5e5;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.connectivity_tag_wrap_new {
			padding: 54px 10% 24px 10%;
			overflow: hidden;

			&:hover {
				.scroll_title_wrap {
					.iconfont_btn {
						opacity: 1;
						transition: all .3s;
					}
				}
			}

			.scroll_title_wrap {
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 58px;
				position: relative;

				&.scroll_title_wrap_center {
					justify-content: center;
				}

				.scroll_title_list {
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					overflow-x: auto;

					.left_shadow {
						width: 40px;
						height: 100%;
						background: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 100%, .14)), color-stop(43%, hsla(0, 0%, 100%, .66)), to(#fff));
						background: -o-linear-gradient(right, hsla(0, 0%, 100%, .14) 0, hsla(0, 0%, 100%, .66) 43%, #fff 100%);
						background: linear-gradient(-90deg, hsla(0, 0%, 100%, .14), hsla(0, 0%, 100%, .66) 43%, #fff);
						top: 0;
						left: -16px;
						position: absolute;
						pointer-events: none;
					}

					.right_shadow {
						width: 40px;
						height: 100%;
						background: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, .14)), color-stop(43%, hsla(0, 0%, 100%, .66)), to(#fff));
						background: -o-linear-gradient(left, hsla(0, 0%, 100%, .14) 0, hsla(0, 0%, 100%, .66) 43%, #fff 100%);
						background: linear-gradient(90deg, hsla(0, 0%, 100%, .14), hsla(0, 0%, 100%, .66) 43%, #fff);
						top: 0;
						right: -16px;
						position: absolute;
						pointer-events: none;
					}

					&::-webkit-scrollbar {
						display: none;
					}
				}

				.scroll_title_item {
					display: flex;
					align-items: center;
					height: 30px;
					font-size: 13px;
					color: #646466;
					padding: 8px 16px;
					border: 1px solid #ccc;
					border-radius: 30px;
					align-items: center;
					margin-right: 16px;
					cursor: pointer;
					transition: all .3s;
					white-space: nowrap;

					&:last-of-type {
						margin-right: 0;
					}

					&:hover {
						color: #19191a;
						border: 1px solid #646466;
					}

					&.scroll_title_item_active {
						color: #19191a;
						font-weight: 600;
						border: 2px solid #4b4b4b;
						padding: 6px 14px;
						background: #f7f7f7;

						&:hover {
							color: #19191a;
							font-weight: 600;
							border-color: 2px solid #4b4b4b;
							padding: 6px 14px;
							background: #f7f7f7;
						}
					}

				}

				.iconfont_btn {

					width: 30px;
					height: 30px;
					border-radius: 50%;
					border: 1px solid #ccc;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					top: 0;

					cursor: pointer;
					position: absolute;
					font-size: 14px;
					color: #4b4b4b;
					transition: all 0.3s;

					&:hover {
						border: 1px solid #646466;
					}

					&.iconfont_disable {
						cursor: not-allowed;

						&:hover {
							border: 1px solid #ccc;
						}
					}
				}

				.iconfont_prev {
					left: -46px;
				}

				.iconfont_next {
					right: -46px;
				}
			}
		}
	}
}