@import "./src/style/variables.less";
/* stylelint-disable declaration-block-no-duplicate-properties */
@nav-size-height: 60px;
@layout-max-width: 1100px;
* {
  font-family: 'Microsoft YaHei';
}

.layout {
  width: 100%;
  height: 100%;

  :global(.arco-layout-sider-light) {
    background: transparent;
    box-shadow: none;
    background: #eff4ff;
  }
}

.layout-navbar {
  position: fixed;
  width: 100%;
  min-width: @layout-max-width;
  top: 0;
  left: 0;
  height: @nav-height;
  z-index: 100;

  &-hidden {
    height: 0;
  }
}

.layout-sider {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--color-text-4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-3);
  }

  &::after {
    // content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: var(--color-border);
  }

  > :global(.arco-layout-sider-children) {
    overflow-y: hidden;
  }

  .collapse-btn {
    height: 24px;
    width: 24px;
    background-color: #fff;
    color: var(--color-text-3);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // 位置
    position: absolute;
    bottom: 20px;
    right: 15px;

    &:hover {
      background-color: var(--color-fill-3);
    }
  }
}

.menu-wrapper {
  overflow: auto;
  height: 100%;

  :global(.arco-menu-vertical .arco-menu-item),
  :global(.arco-menu-vertical .arco-menu-inline-header) {
    margin-bottom: 0;
    @media screen and (min-width: 1200px) {
      line-height: 22px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  :global(.arco-menu-light .arco-menu-pop-header),
  :global(.arco-menu-inline .arco-menu-inline-header),
  :global(.arco-menu-inline .arco-menu-selected),
  :global(.arco-menu-light .arco-menu-item) {
    &:hover {
      background: #fff;
    }
  }

  :global(.arco-menu-light),
  :global(.arco-menu-light .arco-menu-inline-header),
  :global(.arco-menu-light .arco-menu-pop-header),
  :global(.arco-menu-light .arco-menu-item) {
    color: #252733;
    background: transparent;
    font-weight: normal;
    border-radius: 8px;
  }

  :global(.arco-menu-light
      .arco-menu-inline-header.arco-menu-selected
      .arco-icon),
  :global(.arco-menu-light .arco-menu-inline-header .arco-icon),
  :global(.arco-menu-light .arco-menu-item .arco-icon) {
    color: #4e5969;
    margin-right: 8px;
  }

  :global(.arco-menu-vertical .arco-menu-inline-header .arco-menu-icon-suffix) {
    right: 18px;
  }

  :global(.arco-menu-vertical
      .arco-menu-inline-header
      .arco-menu-icon-suffix
      .arco-icon) {
    font-size: 12px;
    margin-right: 0;
  }

  :global(.arco-menu-vertical .arco-menu-inline-header) {
    padding-right: 36px;
  }

  :global(.arco-menu .arco-menu-item.arco-menu-selected) {
    font-weight: normal;
    background: #fff;
    color: #165dff;
    border-radius: 8px;

    :global(.arco-icon) {
      color: #165dff;
    }
  }

  :global(.arco-menu-light .arco-menu-pop-header.arco-menu-selected) {
    background: #fff;
  }

  :global(.arco-menu-item-inner a::after),
  :global(.arco-menu-item a::after) {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  :global(.arco-menu-inline-header) {
    font-weight: 500;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.icon-empty {
  width: 12px;
  height: 18px;
  display: inline-block;
}

:global(.arco-menu-inline-content) {
  .icon-empty {
    width: 2px;
  }
}

:global(.arco-menu-item) {
  :global(.arco-menu-indent:nth-child(2)) {
    display: none;
  }
}

.layout-content {
  // background-color: var(--color-fill-2);
  min-width: @layout-max-width;
  min-height: 100vh;
  transition: padding-left 0.2s;
  box-sizing: border-box;

  :global(.arco-layout-content) {
    margin: 0;
  }
}

.layout-breadcrumb {
  // margin-bottom: 16px;
  background-color: #fff;
  padding: 20px 20px 0;
}
// 数据看板导航栏背景色
.layout-index-breadcrumb {
  background-color: #f6f9fc !important;
  display: none;
}
.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - @nav-size-height);
  background: #fff;
}

:global(.arco-menu-collapse) {
  width: 54px;
  :global(.arco-menu-item) {
    display: flex;
    justify-content: center;
  }
  :global(.arco-menu-pop-header) {
    line-height: 54px;
    overflow: visible;
    svg {
      margin-left: 4px;
    }
  }
  a {
    max-width: 14px;
    overflow: hidden;
    display: block;
    :global(.arco-icon) {
      margin-right: 0 !important;
    }
  }
  .span-hide {
    display: none;
  }
}

:global(.arco-menu-collapse .arco-menu-inner) {
  padding: 4px 8px;
}
:global(.arco-menu-collapse .arco-menu-pop-header) {
  padding: 0 8px;
}
:global(.arco-menu-collapse .arco-menu-pop-header .arco-icon) {
  margin-right: 0;
}
