@import "./src/style/variables.less";
.products_specification_box {
	cursor: pointer;
	margin-bottom: 70px;
}

.products_specification_title {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: #19191A;
	margin-bottom: 12px;
}

.products_specification_empty {
	display: flex;
	width: 100%;
	height: 186px;
	align-items: center;
	justify-content: center;
	background: #F7F7F7;
	color: #252733;
	font-size: 16px;
	line-height: 24px;

}

.products_specification_content {
	.specification_box {
		width: 100%;
	}

	.specification_row {
		width: 100%;
		display: flex;
		flex-direction: row;

		&:nth-child(odd) {
			background: #f7f7f7;
		}

	}

	.specification_item_box {
		width: 50%;
		flex-shrink: 0;
		display: flex;
	}

	.specification_item {
		width: 50%;
		padding: 8px;
		font-size: 14px;
		line-height: 22px;
		color: #19191a;

		.specification_name {
			display: inline-block;
			font-weight: 600;
			word-break: break-all;
		}

		.specification_value {
			word-break: break-all;
		}

		.iconfont_tip {
			color: #8d8d8f;
			font-size: 16px;
			margin-left: 4px;
		}
	}

	.pop_title {
		font-size: 14px;
		line-height: 22px;
		color: #19191a;
		font-weight: 600;
	}

	.pop_content {
		font-size: 13px;
		color: #616265;
		line-height: 22px;
		margin-top: 10px;
	}
}

.reduction_title {
	margin-top: 40px;
	margin-bottom: 7px;

	span {
		font-size: 16px;
		font-weight: 400;
		color: #616265;
		&:first-child {
			font-weight: 600;
			color: #232323;
		}
	}
}

.specification_remarks {
	display: block;
	margin: 18px 0;
	color: #646466;
	font-size: 14px;
	line-height: 22px;
}

.specification_recommended_title {
	margin-top: 40px;
	margin-bottom: 7px;
	font-size: 14px;
	color: #616265;
	font-weight: 400;
}

.specification_recommended_list {
	border: 1px solid #e5e5e5;
	box-sizing: border-box;

	.recommended_item_box {
		display: flex;
		border-bottom: 1px solid #e5e5e5;

		&:last-child {
			border-bottom: none;
		}

		.recommended_item {
			flex: 1;
			flex-shrink: 0;
			font-size: 14px;
			color: #232323;
			padding: 10px 0;
			text-align: center;
			border-right: 1px solid #e5e5e5;

			&:first-child {
				background: #f4f4f3;
			}

			&:last-child {
				border-right: none;
			}
		}
	}
}