@import "./src/style/variables.less";
.wrapper {
  position: relative;
  background-color: var(--color-bg-1);
  height: calc(100vh - 168px);
}

.result {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
