@import "./src/style/variables.less";
.pdf-table {
  height: 700px;
  overflow-y: auto;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead th {
      padding: 12px 20px;
      background: #f2f3f5;
      font-size: 14px;
      color: #252733;
      font-weight: normal;
    }

    tbody tr {
      border-bottom: 1px solid #e5e6eb;

      td {
        :global(.arco-form-item) {
          margin: 16px 0;
        }

        :global(.arco-color-picker-size-default) {
          padding: 4px;
        }

        :global(.arco-color-picker-preview) {
          border-radius: 50%;
        }

        .upload-btn {
          border-radius: 8px;
          background: #f7f8fa;
          box-sizing: border-box;
          border: 1px dashed #e5e6eb;
          width: 100px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .desc {
            color: #4e5969;
            font-size: 14px;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    :global(.arco-btn-outline) {
      border: 1px dashed #165dff;
    }
  }
}
