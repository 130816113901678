@import "./src/style/variables.less";
/* stylelint-disable alpha-value-notation */
.blog-editor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .editor-content {
    display: flex;
    flex: 1;

    .editor-left {
      flex: 1;
      height: 100%;
    }

    .editor-line {
      width: 6px;
      height: 100%;
      position: relative;

      &-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -1px;
        width: 14px;
        height: 44px;
        border-radius: 228px;
        background: #fff;
        border: 1px solid #e5e6eb;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

        &:hover {
          background: #f7f8fa;
        }

        svg {
          fill: inherit !important;
          font-size: 10px;
          position: absolute;
          top: 50%;
          margin-left: 2px;
        }
      }
    }

    .collapse-status {
      &:hover {
        .editor-line-btn {
          display: block;
        }
      }

      .editor-line-btn {
        display: none;
      }
    }

    .editor-right {
      box-sizing: border-box;
      height: calc(100vh - 69px);
      transition: all 0.3s;
      border-left: 1px solid #e5e6eb;
      overflow: auto;
    }
  }
}
