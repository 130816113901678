@import "./src/style/variables.less";
@import 'nprogress/nprogress.css';
@import './variables.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}
.arco-layout-content {
  margin: 0 20px;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border: none;
  box-sizing: border-box;
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 0.6) -6.04%,
        rgba(87, 87, 87, 0.6) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}
ul li {
  list-style-type: none;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://img-en.fs.com/includes/templates/fiberstore/images/opensans.woff2);
  src: local('Open Sans'), local('OpenSans'),
    url(https://img-en.fs.com/includes/templates/fiberstore/images/opensans.woff2)
      format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('opensans semibold webfont'), local('opensans-semibold-webfont'),
    url(https://img-en.fs.com/includes/templates/fiberstore/images/opensans-semibold-webfont.woff2)
      format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url(https://resource.fs.com/icon/SourceSansPro-SemiBold.ttf)
    format('truetype');
  font-display: swap;
}
