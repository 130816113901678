@import "./src/style/variables.less";
.manager_detail {
  height: 100vh;

  .edit_content {
    display: flex;
    height: calc(100% - 77px);
    overflow-y: auto;

    .edit_content_left {
      flex: 1;
      border-right: 1px solid #e5e6eb;
      display: flex;

      .line {
        width: 5px;
        height: 100%;
        background-color: transparent;
        position: relative;
        cursor: pointer;

        &:hover span {
          display: flex;
        }

        .fold {
          right: 0 !important;
          display: flex;

          svg {
            transform: rotate(180deg);
          }
        }

        span {
          display: none;
          border: 1px solid #e5e6eb;
          width: 14px;
          height: 44px;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          border-radius: 228px;
          position: absolute;
          top: calc(50% - 22px);
          right: -8px;
          background-color: #fff;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #f7f8fa;
          }

          svg {
            fill: unset !important;
            font-size: 10px;
          }
        }
      }
    }

    .edit_content_right {
      transition: all 0.3s ease-in-out;
      overflow-y: auto;

      .GlossarySlide {
        padding: 20px;
        width: 100%;

        :global(.arco-form-item.arco-form-item-error) {
          margin-bottom: 10px;
        }

        .resizebox_line {
          height: 1px;
          background-color: rgb(229, 230, 235);
          margin-bottom: 20px;
        }

        .bindTags {
          width: 100%;
          height: 106px;
          overflow-y: auto;
          background: #f7f8fa;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 12px;
          display: flex;
          flex-wrap: wrap;
        }

        .uploadCover {
          width: 100%;
          height: 144px;
          background-color: #f7f8fa;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 8px;

          .imgBox {
            position: relative;
            width: 100%;

            &:hover {
              > div {
                opacity: 1;
              }
            }

            img {
              width: 100%;
              height: 144px;
              object-fit: contain;
              border-radius: 8px;
              cursor: auto;
            }

            > div {
              position: absolute;
              bottom: 0;
              color: #fff;
              width: 100%;
              height: 32px;
              background: rgba(0, 0, 0, 0.4);
              line-height: 32px;
              text-align: center;
              transition: 0.5s;
              opacity: 0;
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
            }
          }

          > div {
            font-size: 14px;
            line-height: 22px;
            color: #165dff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }

        .uploadCoverDefault {
          width: 100px;
          height: 100px;
          background-color: #f7f8fa;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 8px;

          .imgBox {
            position: relative;
            width: 100%;

            &:hover {
              > div {
                opacity: 1;
              }
            }

            img {
              width: 100%;
              height: 100px;
              object-fit: contain;
              border-radius: 8px;
              cursor: auto;
            }

            > div {
              position: absolute;
              left: 0;
              bottom: 0;
              color: #fff;
              width: 100%;
              height: 100px;
              background: rgba(0, 0, 0, 0.4);
              line-height: 32px;
              text-align: center;
              transition: 0.5s;
              opacity: 0;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              .editBtn {
              }
            }
          }

          > div {
            font-size: 14px;
            line-height: 22px;
            color: #165dff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }

        .switchFromItem {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .label {
            font-size: 14px;
            color: rgb(78, 89, 105);

            strong {
              color: rgb(245, 63, 63);
              margin-right: 8px;
            }
          }
        }
      }

      :global {
        .arco-input,
        .arco-textarea,
        .arco-select-view {
          border-radius: 8px;
        }

        .arco-upload {
          width: 100%;
        }
      }
    }
  }
}
