@import "./src/style/variables.less";
.image-view {
  height: 144px;
  box-sizing: border-box;

  .image-label {
    color: #252733;
    font-size: 14px;

    &::before {
      content: '*';
      color: #ff4d4f;
      margin-right: 8px;
    }
  }

  &-content {
    height: 144px;
    box-sizing: border-box;
    cursor: pointer;

    &-add {
      width: 100%;
      height: 100%;
      background: #f7f8fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 36px 0;
      color: #165dff;
      border-radius: 8px;
      box-sizing: border-box;

      span {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;
      }
    }

    &-preview {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 144px;
      overflow: hidden;

      img {
        width: 100%;
        height: 144px;
        border-radius: 8px;
      }

      &-mask {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: -30px;
        left: 0;
        background: rgba(0, 0, 0, 40%);
        font-size: 14px;
        color: #fff;
        border-radius: 0 0 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;

        &-icon {
          display: inline-block;
          width: 50%;
          text-align: center;
          position: relative;

          &:first-child::before {
            content: '';
            position: absolute;
            right: 0;
            height: 14px;
            top: 3px;
            border-right: 1px solid #c9cdd4;
          }
        }
      }

      &:hover {
        .image-view-content-preview-mask {
          bottom: 0;
        }
      }
    }
  }
}
