@import "./src/style/variables.less";
.nav {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e6eb;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 142px;
      height: auto;
    }
    span {
      margin-left: 24px;
      color: #252733;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
    }
  }
  .right {
    display: flex;
    .desc {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: #165dff;
        font-weight: 600;
      }
    }
    svg {
      color: #165dff;
      margin-right: 6px;
      font-size: 18px;
    }
    .line {
      width: 1px;
      background: #d8d8d8;
      margin: 0 24px;
      margin-right: 8px;
    }
  }
}
