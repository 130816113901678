@import "./src/style/variables.less";
.self_empty{
    img{
        height: 200px;
        width: 200px;
    }
    
    .message{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5969;
        margin-top: 16px;
    }
}