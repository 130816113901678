@import "./src/style/variables.less";
.products_attr_box {
	flex: 1 1 auto;

	.attr_box {
		height: 500px;
		background: #F6F6F6;
		color: #25273347;
		cursor: not-allowed;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		margin-bottom: 24px;
	}
}

.item_spotlights_box {
	cursor: pointer;
    padding: 20px 32px 30px 32px;
    background: #fff;
    margin-bottom: 20px;
	.item_spotlights_title {
		font-size: 16px;
		line-height: 30px;
		font-weight: 600;
		color: #19191A;
		padding-bottom: 20px;
		margin-bottom: 30px;
		border-bottom: 1px solid #E5E5E5;
		display: flex;
		justify-content: space-between;
	}
	.item_spotlights_ctn {
		cursor: pointer;
		.spotlights_item {
			position: relative;
			font-size: 12px;
			line-height: 18px;
			color: #19191a;
			padding: 0 0 0 14px;
			margin: 0 0 7px 0;
			&:before {
				display: block;
				content: " ";
				width: 4px;
				height: 4px;
				border-radius: 4px;
				background: #646466;
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -2px;
			}

			a {
				color: #0060bf;
			}
		}
	}

	.item_spotlights_empty {
		background: #f7f7f7;
		height: 122px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #252733;
		font-size: 16px;
		cursor: pointer;
	}
}