@import "./src/style/variables.less";
.software_spotlights_wrap {
	cursor: pointer;
	padding-bottom: 32px;
	border-bottom: 1px solid #E5E5E5;
	margin-bottom: 80px;

	.software_spotlights_title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: #19191A;
		margin-bottom: 32px;
	}

	.software_spotlights_conetnt {
		cursor: pointer;
	}

	.software_spotlights_empty {
		background: #f7f7f7;
		height: 122px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #252733;
		font-size: 16px;
		cursor: pointer;
	}
}

.software_spotlights_list {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	.software_spotlights_box {
		width: 33.33%;
		flex-shrink: 0;
		padding-right: 2%;
		margin-bottom: 22px;

		.spotlights_img_box {
			width: 31px;
			margin-bottom: 16px;

			>img {
				display: block;
				max-width: 100%;
			}
		}

		.spotlights_title {
			margin-top: 10px;
			font-size: 14px;
			line-height: 22px;
			color: #19191a;
			font-weight: 600;

		}

		.spotlights_list {
			padding-right: 10%;

			.spotlights_item {
				padding-left: 10px;
				position: relative;
				font-size: 13px;
				line-height: 22px;
				color: #646466;

				&:before {
					font-style: normal;
					font-weight: 400;
					text-align: center;
					content: ".";
					position: absolute;
					left: -1px;
					top: -8px;
					color: #646466;
					font-size: 28px;
					line-height: inherit;
				}
			}
		}
	}
}