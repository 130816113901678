@import "./src/style/variables.less";
.pdf-view {
  margin-top: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-size: 14px;

    .title {
      color: #252733;

      &::before {
        content: '*';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0 8px;
        color: #ea0000;
      }
    }

    .operation {
      color: #165dff;

      span {
        cursor: pointer;
      }

      span:first-child {
        &::after {
          content: '|';
          margin: 0 8px;
          color: #d8d8d8;
        }
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .card-img {
        padding: 2px;
        height: 144px;
        width: 104px;
        position: relative;

        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }

        margin-bottom: 8px;

        .card-mask {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 100px;
          height: 140px;
          // background-color: rgba(0, 0, 0, 60%);
          border-radius: 8px;
        }
      }

      .card-title {
        font-size: 13px;
        width: 100px;
        text-align: center;
        line-height: 20px;
      }
    }

    .card-active {
      .card-img {
        border: 2px solid #165dff;
        border-radius: 8px;

        .card-mask {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 96px;
          height: 136px;
          // background-color: rgba(0, 0, 0, 60%);
          border-radius: 8px;
        }
      }

      .card-title {
        color: #165dff;
      }
    }
  }
}
