@import "./src/style/variables.less";
.product_detail {
    display: flex;
    .product_detail_l {
      margin-right: 32px;
      width: 288px; 
      .product_detail_data {
        padding: 16px;
        margin-bottom: 40px;
        background-color: #fff;
        > div:nth-child(1) {
        margin-bottom: 12px;
        }
        .product_detail_data_id{
            cursor: pointer;
            color: #165DFF;
        }
      }
      .product_detail_anchor {
        .product_detail_anchor_item {
            border-left: 2px solid #e5e6eb;
            padding: 0px 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
           
            .product_detail_anchor_icon {
                opacity: 0;
                color: #165DFF;
            }
            > span {
                padding: 4px 8px;
                margin-right: 12px;
               }
            &:hover {
                .product_detail_anchor_icon {
                   opacity: 1;
                }
                > span {
                 background-color: #F2F3F5;
                }
            }
        }
        .product_detail_anchor_item_active {
            border-left: 2px solid #165DFF;
            >span {
                color: #165DFF;
            }
        }
      
      }
    }
    .product_detail_r {
      width: 100%;
      max-height: 90vh;
      overflow-y: auto;
      position: relative;
      .product_detail_r_container {
        width: 95%;
        .product_describe_box {
          padding: 0px 32px;
          overflow: hidden;
          background: #fff;
          margin-bottom: 20px;
    
          .detail_nav_box {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 24px;
    
            .detail_nav_item {
              display: inline-block;
              line-height: 22px;
              color: #89898C;
              font-weight: 400;
              padding: 24px 0 12px 0;
              font-size: 16px;
              margin-right: 24px;
              cursor: pointer;
              position: relative;
              transition: all .3s;
    
              &:hover {
                color: #646466;
              }
    
              &.detail_nav_item_active {
                color: #19191a;
    
                &:after {
                  content: "";
                  display: block;
                  width: 40px;
                  height: 3px;
                  position: absolute;
                  background: #C00000;
                  left: 50%;
                  bottom: -1px;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    
    }
}