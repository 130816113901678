@import "./src/style/variables.less";
.editor-form {
  box-sizing: border-box;

  .editor-form-top {
    padding: 20px 20px 0;

    .upload-btn {
      border-radius: 8px;
      background: #f7f8fa;
      box-sizing: border-box;
      border: 1px dashed #e5e6eb;
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .desc {
        color: #4e5969;
        font-size: 14px;
      }
    }

    :global(.arco-select-size-large.arco-select-single input) {
      font-size: 14px;
    }

    :global(.arco-select-size-large.arco-select-single .arco-select-view) {
      font-size: 14px;
    }

    :global(.arco-cascader-size-large.arco-cascader-single input) {
      font-size: 14px;
    }

    :global(
        .arco-cascader-size-large.arco-cascader-single .arco-cascader-view
      ) {
      font-size: 14px;
    }
  }

  .editor-form-footer {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;

    button {
      width: 212px;
    }
  }
}
