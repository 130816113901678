@import "./src/style/variables.less";
/* stylelint-disable length-zero-no-unit */
/* stylelint-disable rule-empty-line-before */
.navbar {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
}

.left {
  display: flex;
  align-items: center;
  width: 200px;

  .logo-name {
    position: relative;
    padding-left: 10px;

    &::after {
      content: '';
      width: 1px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 6px;
      background: #5a5a5a;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 48px;
    .logo {
      padding-left: 13px;

      .logo-name {
        display: none;
      }

      :global(.logo_svg__cls-1) {
        display: none;
      }
    }
  }
}

.menuTag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
  padding-left: 4px;
  background: var(--color-bg-2);
  // border-top-left-radius: 30px;
  border-bottom: 1px solid var(--color-border);

  &::-webkit-scrollbar {
    display: none;
  }

  .tagbox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: #f7f8fa;
    border-radius: 8px;
    color: #9fa2b4;
    margin-left: 16px;
    cursor: pointer;
    // width: 84px;
    font-size: 14px;
    line-height: 22px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .tagActive {
    color: #252733;
  }
}

.logo {
  display: flex;
  align-items: center;
  // width: 200px;
  padding-left: 20px;
  box-sizing: border-box;

  img {
    width: 142px;
    height: auto;
  }
}
.logo_fold {
  display: flex;
  align-items: center;
  // width: 200px;
  padding-left: 12px;
  box-sizing: border-box;
  width: 50px;
  overflow: hidden;
  img {
    width: 142px;
    height: auto;
  }
}

.logo-name {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
  font-family: 'PingFang SC';
}

.right {
  display: flex;
  list-style: none;
  background: var(--color-bg-2);
  margin: 0;
  padding: 14px 20px;
  align-items: center;
  border-bottom: 1px solid var(--color-border);

  li {
    padding: 0 8px;
    display: flex;
    align-items: center;
  }
  .instructions{
    color: #165DFF;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
  }

  .websiteInfo{

    .website_box{
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      cursor: pointer;

      p{
        color: #165DFF;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .username {
      color: #252733;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.2px;
      width: 100%;
      text-align: right;
    }

    .noun {
      color: #9fa2b4;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .turnRight {
    width: 38px;
    height: 38px;
    left: 1347px;
    background: #f7f8fa;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 16px;
  }
   .go_erp {
    padding: 0;
    margin-left: 24px;
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
  img {
    width: 32px;
    height: 32px;
    margin-left: -1px;
  }
   }
  .gohome {
    margin-left: 24px;
    cursor: pointer;
    border: 1px solid #f2f3f5;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    :global(.arco-icon) {
      color: #4e5969;
    }
  }

  .line {
    width: 0px;
    height: 24px;
    border: 1px solid #eef1f4;
    margin: 0 24px;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: var(--color-text-1);
  }
}

.username {
  cursor: pointer;
}

.round {
  :global(.arco-input-inner-wrapper) {
    border-radius: 16px;
  }

  svg {
    font-size: 16px;
  }
}

.dropdown-icon {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.fixed-settings {
  position: fixed;
  top: 280px;
  right: 0px;

  svg {
    font-size: 18px;
    vertical-align: -4px;
  }
}
