@import "./src/style/variables.less";
.quality_certifications_box {
	cursor: pointer;
	padding-bottom: 20px;

	.quality_certifications_title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: #19191A;
		margin-bottom: 20px;
	}

	.quality_certifications_content {
		.certifications_icon_box {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
		.certifications_icon {
			display: inline-block;
			height: 66px;
			width: 66px;
			margin-right: 35px;
			margin-bottom: 20px;
			>img{
				display: block;
				max-width: 100%;
				max-height: 100%;
				margin: auto;
			}
		}
	}
	.notes {
		font-size: 14px;
    	line-height: 22px;
		color:#646466;
		padding: 10px 0;
		.notes_title {
			font-weight: 600;
		}
	}

	.quality_testing_program_empty {
		display: flex;
		width: 100%;
		height: 100px;
		align-items: center;
		justify-content: center;
		background: #F7F7F7;
		color: #252733;
		font-size: 16px;
		line-height: 24px;
	}
}