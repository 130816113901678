@import "./src/style/variables.less";
.login_info {
  display: flex;
  .info {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    justify-content: center;
    .username {
      color: #252733;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.2px;
      width: 100%;
      text-align: right;
    }

    .noun {
      color: #9fa2b4;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
