@import "./src/style/variables.less";
.tag_wrap {
	text-align: center;

	.tag_title {
		padding: 23px 0 30px;
		font-size: 14px;
		line-height: 22px;
		color: #19191a;
	}

	.tag_content {
		position: relative;
		display: inline-block;
		max-width: 100%;
		position: relative;

		.tag_img {
			display: block;
			max-width: 100%;
			position: relative;
		}

		.point_item {
			position: absolute;
			text-align: center;
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-left: -16px;
			margin-top: -16px;
			border-radius: 100%;
			background: rgba(0, 0, 0, .2);
			cursor: pointer;
			transition: all .3s;

			&:hover {
				background: rgba(0, 0, 0, .3);
			}

			.iconfont_tag {
				display: inline-block;
				width: 16px;
				height: 16px;
				line-height: 16px;
				font-size: 16px;
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -8px;
				margin-top: -8px;
				transition: all .3s;
				opacity: 1;
			}

			.iconfont_close {
				display: inline-block;
				width: 16px;
				height: 16px;
				line-height: 16px;
				font-size: 16px;
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -8px;
				margin-top: -8px;
				opacity: 0;
				transition: all .3s;

			}

			&.point_item_active {
				background: rgba(0, 0, 0, .3);

				.iconfont_tag {
					opacity: 0;
				}

				.iconfont_close {
					opacity: 1;
					transform: rotate(90deg);
				}
			}
		}
	}
}


.point_wrap {
	:global {
		.arco-popover-content {
			padding: 0;
		}
	}

	.point_content {
		width: 300px;
		padding: 12px;

		.point_box {
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			.products_img {
				display: inline-block;
				width: 100px;
				height: 100px;
				flex-shrink: 0;
				margin-right: 10px;

				img {
					display: block;
					max-width: 100%;
					margin: 0 auto;
				}
			}

			.products_info {
				flex: 1 1 auto;
				overflow: hidden;

				.products_name {
					width: 100%;
					overflow: hidden;
					display: flex;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					font-size: 14px;
					line-height: 22px;
					color: #19191a;
					margin: 15px auto 10px auto;
					text-align: left;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				.products_price_box {
					display: flex;
					justify-content: space-between;

					.products_price {
						font-size: 16px;
						line-height: 24px;
						color: #19191a;
						font-weight: 600;
					}


				}

				.products_id {
					display: none;
				}

				.price_info {
					display: none;
				}

				.products_cart {
					display: none;
				}

				.iconfont_cart {
					display: flex;
					width: 32px;
					height: 32px;

					transition: all 0.3s;
					border-radius: 50%;

					justify-content: center;

					align-items: center;
					color: #fff;
					font-size: 16px;
					background: rgba(0, 0, 0, 0.2);
					cursor: pointer;
				}
			}
		}

		&.point_content_more {
			width: auto;
			min-width: 300px;
			display: flex;

			.products_img {
				display: block;
				width: 60px;
				height: 60px;
				margin: 0 auto;
			}

			.products_info {
				flex: 1;
				width: 100%;
			}

			.point_box {
				display: flex;
				flex-direction: column;
				width: 140px;
				flex-shrink: 0;
				padding: 5px 10px;

				.products_name {
					display: none;
				}

				.products_price_box {
					display: none;
				}

				.products_id {
					display: block;
					font-size: 14px;
					line-height: 22px;
					text-align: center;
					font-weight: 400;
					margin-top: 15px;
					margin-bottom: 10px;
					color: #19191a;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				.products_cart {
					display: flex;
					justify-content: center;

				}

				.price_info {
					display: block;
					font-size: 16px;
					line-height: 24px;
					color: #19191a;
					font-weight: 600;
					margin-bottom: 8px;
					text-align: center;
				}
			}
		}

		.iconfont_close {
			position: absolute;
			right: 12px;
			top: 12px;
			font-size: 12px;
			line-height: 18px;
			color: #4c4948;
			cursor: pointer;
		}

	}



}