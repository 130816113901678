@import "./src/style/variables.less";
/* stylelint-disable rule-empty-line-before */
.detailRawer {
  // &.fixation {
  //   :global {
  //     .arco-drawer-content {
  //       overflow: hidden;
  //     }
  //   }
  // }
  .sync {
    height: 38px;
    padding: 0 20px;
    border-radius: 38px;
  }
  :global {
    .upload_p_v {
      > p {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        button:not(:last-child) {
          margin-right: 16px;
        }
      }
      .arco-form-item-control {
        min-height: 0;
      }
    }
    .link_exhibition {
      margin-bottom: 12px;
      > div {
        padding: 0 16px;
        &:first-child {
          border-radius: 8px 8px 0px 0px;
          background: rgba(242, 243, 245, 0.8);
          margin-bottom: 10px;
          span {
            line-height: 42px;
          }
        }
        display: flex;
        img {
          height: 100px;
          vertical-align: top;
        }
        > span:not(:first-child) {
          flex: 3;
          padding: 0 20px;
          &:last-child {
            flex: 2;
          }
        }
        > span:first-child,
        img {
          width: 100px;
          text-align: center;
        }
      }
    }
    .arco-drawer-footer {
      button {
        margin-left: 16px;
      }
    }

    .arco-drawer-header-title {
      font-size: 20px;
      font-weight: 400;
      color: #252733;
    }
    .arco-drawer .arco-drawer-close-icon {
      top: 31px;
    }

    .arco-drawer-footer {
      border: none;
    }
  }
}
