@import "./src/style/variables.less";
.nav {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e6eb;

  .logo {
    display: flex;
    align-items: center;

    img {
      width: 142px;
      height: auto;
    }

    span {
      margin-left: 24px;
      color: #252733;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
    }
  }
}
